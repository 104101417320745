<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>仮伝印刷</v-card-title>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <!-- 売上担当者コード -->
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      v-model="cssManagerCodeName"
                      label="売上担当者コード"
                      :items="cssManagerList"
                      persistent-hint
                      :hint="`完全一致`"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- 得意先コード開始 -->
                  <v-col cols="12" sm="3">
                    <v-autocomplete
                      v-model="clientCodeBegin"
                      label="得意先コード開始"
                      :items="cssClientCodeList"
                      item-text="comboItem"
                      item-value="code"
                      persistent-hint
                      :hint="`範囲検索`"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- 得意先コード終了 -->
                  <v-col cols="12" sm="3">
                    <v-autocomplete
                      v-model="clientCodeFinish"
                      label="得意先コード終了"
                      :items="cssClientCodeList"
                      item-text="comboItem"
                      item-value="code"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- 伝票日付 -->
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="slipDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="slipDateRange"
                          label="仮伝日付"
                          append-icon="mdi-calendar"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                          :hint="`完全一致/範囲検索`"
                          @click:clear="slipDates = []"
                          @click:append="slipDateMenu = !slipDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="slipDates"
                        range
                        @input="slipDateMenu = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- 着日 -->
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="scInDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="scInDateRange"
                          label="着日"
                          append-icon="mdi-calendar"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          persistent-hint
                          :hint="`完全一致/範囲検索`"
                          @click:clear="scInDates = []"
                          @click:append="scInDateMenu = !scInDateMenu"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="scInDates"
                        range
                        @input="scInDateMenu = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- 仮伝No開始 -->
                  <v-col cols="12" sm="3">
                    <v-text-field 
                      v-model="tempNoBegin" 
                      label="仮伝No開始"
                      :rules="[number, limit_length5]"
                      counter="5"
                      clearable
                      persistent-hint
                      :hint="`範囲検索`"
                      hide-spin-buttons
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <!-- 仮伝No終了 -->
                  <v-col cols="12" sm="3">
                    <v-text-field 
                      v-model="tempNoFinish" 
                      label="仮伝No終了"
                      :rules="[number, limit_length5]"
                      counter="5"
                      clearable
                      hide-spin-buttons
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <!-- 現場名 -->
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="spotName"
                      label="現場名"
                      :rules="[limit_length20]"
                      persistent-hint
                      :hint="`完全一致`"
                      counter="20"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 仮伝印刷済 -->
                  <v-col cols="12" sm="4">
                    <v-checkbox
                      v-model="tempPrintDone"
                      :label="`仮伝印刷済`"
                      dense
                    ></v-checkbox>
                  </v-col>
                  <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                  <!-- 連番を振る条件 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="serialNumberCondition"
                      label="連番を振る条件"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio label="現場名混在で1伝票" value="0"></v-radio>
                      <v-radio label="現場名ごと複数伝票" value="1"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-divider class="hidden-sm-and-down mt-5" vertical></v-divider>
                  <!-- 単価記載方法 -->
                  <v-col cols="12" sm="4">
                    <v-radio-group
                      v-model="unitPriceDisplay"
                      label="単価記載方法"
                      mandatory
                      dense
                      hide-details="false"
                    >
                      <v-radio label="単価あり" value="0"></v-radio>
                      <v-radio label="単価なし(全て)" value="1"></v-radio>
                      <v-radio label="単価なし(仮伝のみ)" value="2"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center">
              <!-- PDF出力 -->
              <v-btn
                color="secondary"
                v-on:click="onClickPdfPrint"
                rounded
                class="my-2"
              >
                <v-icon left> mdi-file-find </v-icon>プレビュー
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// graphqlのインポート
import { API, graphqlOperation } from "aws-amplify";
import {
  getM_SHOKISETTEI,
  listV_HACCHU_DATA_WITH_MEISAI,
} from "@/graphql/queries";
import {
  executeHanbaikanriSql
} from "@/graphql/mutations";
import { ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
import { base64Encode } from '@/assets/js/common';
export default {
  name: "TempPrint",

  data: () => ({
    // 変数定義
    // 売上担当者リスト
    cssManagerList: [],
    // 売上担当者コード：担当者名前
    cssManagerCodeName: null,
    // CSS得意先コードリスト
    cssClientCodeList: [],
    // 得意先コード開始
    clientCodeBegin: null,
    // 得意先コード終了
    clientCodeFinish: null,
    // 伝票日付
    slipDates: [],
    // 伝票日付メニュー
    slipDateMenu: false,
    // 着日
    scInDates: [],
    // 着日メニュー
    scInDateMenu: false,
    // 仮伝No開始
    tempNoBegin: null,
    // 仮伝No終了
    tempNoFinish: null,
    // 現場名
    spotName: null,
    // 送信が成功したかどうかのフラグ
    sendSuccessFlg: false,
    // 仮伝印刷済
    tempPrintDone: false,
    // 連番を振る条件
    serialNumberCondition: null,
    // 単価記載方法
    unitPriceDisplay: null,
    // ログインユーザー情報
    loginUserInfo: null,
    // ログイン中ユーザーの所属部門情報
    departmentInfo: null,
    // データベース照会結果
    sqlSearchListResult: [],
    // 単価記載方法初期値
    defaultUnitPriceDisplay: "2",

    // 入力規則
    // 入力必須の制約
    required: (value) => !!value || "必ず入力してください",
    // 桁数の制約 最大5桁
    limit_length5: (value) =>
      value == null || value.length <= 5 || "5桁以内で入力してください",
    // 文字数の制約 最大20文字
    limit_length20: (value) =>
      value == null || value.length <= 20 || "20文字以内で入力してください",
    // 数値の制約
    number: (value) =>
      value == null || /^[0-9,.-]*$/.test(value) || "数値で入力してください",
  }),
  computed: {
    slipDateRange: {
      get() {
        let rangeText = null;
        if (this.slipDates.length > 0) {
          if (this.slipDates[0] === this.slipDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.slipDates[0];
          } else if (this.slipDates[0] > this.slipDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.slipDates[1]} ~ ${this.slipDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.slipDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
    scInDateRange: {
      get() {
        let rangeText = null;
        if (this.scInDates.length > 0) {
          if (this.scInDates[0] === this.scInDates[1]) {
            // startとendで同じ日付が選択された場合、日付1つのみ表示
            rangeText = this.scInDates[0];
          } else if (this.scInDates[0] > this.scInDates[1]) {
            // startよりendの方が日付が若い場合、日付を左右入れ替え、" ~ "で分けて表示
            rangeText = `${this.scInDates[1]} ~ ${this.scInDates[0]}`;
          } else {
            // startとendを" ~ "で分けて表示
            rangeText = this.scInDates.join(" ~ ");
          }
        }
        return rangeText;
      },
      set(value) {
        console.log("set： value = " + value);
      },
    },
  },
  /**
   * created ライフサイクルフック
   */
  created: async function () {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);

    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // 初期化処理の実行
    if (!(await this.initialize())) {
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // バリデーションエラーをリセット
    this.$refs.form.resetValidation();
    // ローディングを解除(TODO：仮対応、製造時に解除のタイミングは要検討)
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // データ取得
      let condition = { BUMON_CD: this.loginUserInfo.BUMON_CD };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(getM_SHOKISETTEI, condition)
        );
        // 結果判定
        if (result.data.getM_SHOKISETTEI) {
          // データを取得できた場合
          // ログイン中ユーザーの所属部門情報を取得
          this.departmentInfo = result.data.getM_SHOKISETTEI;
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
         if (result.errors) {
            // データを取得できなかった場合
            // @TODO データ取得無しの場合の処理を以下に記載。
            // ログ出力(SQL実行でエラー)
            addLog('ERROR', {message: MSG.ERROR["3010"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // データ0件時
            // ログ出力(SQL-end_0)
            addLog('INFO', {message: MSG.INFO["1005"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          }
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "getM_SHOKISETTEI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     * ・単価記載方法初期値設定
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の表示
      this.$store.commit("setLoading", true);

      // 単価記載方法初期値設定(単価なし(仮伝のみ))
      this.unitPriceDisplay = this.defaultUnitPriceDisplay;

      // 販売管理システムの得意先基本情報テーブルから得意先コードを取得。
      // 得意先コード用コンボボックスのリスト生成
      let sqlSearchList = [];
      sqlSearchList = [
        "SELECT TK_KANA, TK_TORCD FROM TOKUI" +
          ` WHERE TK_BASCD = ` + this.loginUserInfo.BUMON_CD +
          ` AND TK_EDELKB <> 1` +
          ` AND SUBSTR(TK_TORCD, -2) <> 99` +
          ` ORDER BY TK_KANA`,
      ];
      if (!(await this.executeHanbaikanriSql(sqlSearchList))) {
        return false;
      }
        this.cssClientCodeList = [];
      for (const data of this.sqlSearchListResult[0]) {
          this.cssClientCodeList.push({
            comboItem: data.TK_TORCD + "：" + data.TK_KANA,
            code: data.TK_TORCD,
          });
        }

      // 売上担当者コード用コンボボックスのリスト生成
      this.cssManagerList = [];
      let condition = {
        where_options:
          "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
          " GROUP BY CSS_URIAGE_TANTOSHA_CD" +
          " ORDER BY CSS_URIAGE_TANTOSHA_CD",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          for (const data of resultData) {
            if (data.CSS_URIAGE_TANTOSHA_CD !== null) {
              this.cssManagerList.push(data.CSS_URIAGE_TANTOSHA_CD);
            }
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        console.log(JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * PDF出力ボタン
     * ・仮伝で必要な情報を取得する
     * ・仮伝印刷フラグ、仮伝印刷日時を更新する
     */
    async onClickPdfPrint() {
      // メソッド名を定義
      const method_name="onClickPdfPrint"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      const form = this.$refs.form;
      if (form.validate()) {
        // すべてのバリデーションが通過したときのみif文の中に入る
        this.sendSuccessFlg = true;
        console.log("バリデーションOK");
      } else {
        this.sendSuccessFlg = false;
        console.log("バリデーションNG");
        // 最初のエラー箇所へスクロール
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 検索条件用のWHERE句生成処理
      let whereOptions = await this.setWhereOptionsForSearchOrder();
      // 仮伝情報を取得する（発注明細データ取得VIEW）
      if (!(await this.getSearchItemList(whereOptions))) {
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 1件も取得できなかった場合
      if (this.searchItemList.length == 0) {
        alert("条件に合うレコードがありません。")
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 仮伝PDF出力
      const options = {
        whereOptions: whereOptions,
        serialNumberCondition: this.serialNumberCondition,
        unitPriceDisplay: this.unitPriceDisplay,
        tempPrintDone: this.tempPrintDone
      };
      const encoded = await base64Encode(JSON.stringify(options));
      //console.log({encoded});
      let route = this.$router.resolve({ name: 'TempDeliverySlip', params: { params: encoded }});
      window.open(route.href, '_blank');

      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 検索条件用のWHERE句生成処理
     */
    async setWhereOptionsForSearchOrder() {
      // メソッド名を定義
      const method_name="setWhereOptionsForSearchOrder"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let whereOptions = "";
      // 検索条件 仮伝日付は必須
      whereOptions += " AND KARIDEN_DATE IS NOT NULL";

      // 検索条件 売上担当者コード
      whereOptions +=
        this.cssManagerCodeName == null ? "" : ` AND CSS_URIAGE_TANTOSHA_CD = '${this.cssManagerCodeName}'`;

      // 検索条件 得意先コード     
      if (
        this.clientCodeBegin !== null ||
        this.clientCodeFinish !== null
      ) {
        if (
          this.clientCodeBegin !== null &&
          this.clientCodeFinish !== null
        ) {
          whereOptions += ` AND CSS_TOKUISAKI_CD BETWEEN ${this.clientCodeBegin} AND ${this.clientCodeFinish}`;
        } else if (this.clientCodeBegin !== null) {
          whereOptions += ` AND CSS_TOKUISAKI_CD >= ${this.clientCodeBegin}`;
        } else {
          whereOptions += ` AND CSS_TOKUISAKI_CD <= ${this.clientCodeFinish}`;
        }
      }

      // 検索条件 仮伝日付
      // 仮伝日付を昇順ソートする
      this.slipDates.sort((a, b) => new Date(a) - new Date(b));
      if (this.slipDateRange !== null) {
        if (this.slipDates.length > 1) {
          whereOptions += ` AND KARIDEN_DATE BETWEEN '${this.slipDates[0]}' AND '${this.slipDates[1]}'`;
        } else {
          whereOptions += ` AND KARIDEN_DATE = '${this.slipDates[0]}'`;
        }
      }

      // 検索条件 着日
      // 着日を昇順ソートする
      this.scInDates.sort((a, b) => new Date(a) - new Date(b));
      if (this.scInDateRange !== null) {
        if (this.scInDates.length > 1) {
          whereOptions += ` AND CHAKUBI_DATE BETWEEN '${this.scInDates[0]}' AND '${this.scInDates[1]}'`;
        } else {
          whereOptions += ` AND CHAKUBI_DATE = '${this.scInDates[0]}'`;
        }
      }

      // 検索条件 仮伝出力ナンバー
      if (
        this.tempNoBegin !== null ||
        this.tempNoFinish !== null
      ) {
        if (
          this.tempNoBegin !== null &&
          this.tempNoFinish !== null
        ) {
          whereOptions += ` AND URIDEN_NO BETWEEN ${this.tempNoBegin} AND ${this.tempNoFinish}`;
        } else if (this.tempNoBegin !== null) {
          whereOptions += ` AND URIDEN_NO >= ${this.tempNoBegin}`;
        } else {
          whereOptions += ` AND URIDEN_NO <= ${this.tempNoFinish}`;
        }
      }

      // 検索条件 現場名
      whereOptions +=
        this.spotName == null ? "" : ` AND GENBA_NAME = '${String(this.spotName).replace(/\t+/g, " ")}'`;

      // 検索条件 仮伝印刷済
      if (this.tempPrintDone == true) {
        whereOptions += ` AND KARIDEN_INSATSU_FLG = 1`;
      } else {
        whereOptions += ` AND KARIDEN_INSATSU_FLG = 0`;
      }

      // 検索条件 部門コード,伝票区分
      whereOptions +=
        ` AND BUMON_CD = '${this.loginUserInfo.BUMON_CD}' AND DENPYO_KBN = 1`;

      // ソート条件 現場混在か現場ごとかによってソート順を変更する
      if (this.serialNumberCondition == 0) {
        // 現場混在（CSS得意先／名称, 仮伝日付, 売伝NO／番号,CSS売上担当者／コード, 現場名, 注文番号, 明細Noでソート）
        whereOptions += ` ORDER BY CSS_TOKUISAKI_NAME, KARIDEN_DATE, URIDEN_NO, CSS_URIAGE_TANTOSHA_CD, GENBA_NAME, CHUMON_NO, MEISAI_NO`;
      } else {
        // 現場ごと（CSS得意先／名称, 仮伝日付, 現場名, 売伝NO／番号,CSS売上担当者／コード, 注文番号, 明細Noでソート）
        whereOptions += ` ORDER BY CSS_TOKUISAKI_NAME, KARIDEN_DATE, GENBA_NAME, URIDEN_NO, CSS_URIAGE_TANTOSHA_CD, CHUMON_NO, MEISAI_NO`;
      }
      
      console.log("検索条件  " + whereOptions)
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return whereOptions;
    },
    /**
     * 仮伝情報を取得する（発注明細データ取得VIEW）
     */
    async getSearchItemList(whereOptions) {
      // メソッド名を定義
      const method_name="getSearchItemList"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // データ取得
      let condition = {
        where_options: whereOptions,
      };
      console.log("condition = " + JSON.stringify(condition));
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listV_HACCHU_DATA_WITH_MEISAI, condition)
        );
        // 結果判定
        if (result.data.listV_HACCHU_DATA_WITH_MEISAI) {
          // データを取得できた場合
          // 取得結果を格納
          this.searchItemList = [];
          let resultData = result.data.listV_HACCHU_DATA_WITH_MEISAI;
          for (const data of resultData) {
            this.searchItemList.push({
              orderDataId: data.HACCHU_DATA_ID,
              orderBranchNo: data.MEISAI_NO,
              productCode: data.SHOHIN_CD,
              cssClientCode: data.CSS_TOKUISAKI_CD,
              cssClientName: data.CSS_TOKUISAKI_NAME,
              tempSlipNo: data.URIDEN_NO,
              tempSlipPrintDate: data.KARIDEN_DATE,
              departmentName: this.departmentInfo.BUMON_NAME,
              managerName: null,
              productName: data.SHOHIN_NAME,
              makerName: null,
              returnProductFlg: null,
              amount: data.SURYO_SU,
              customerWholesaleCost: null,
              customerWholesalePrice: null,
              lastCost: data.SAISHU_SHIIRE_KAKAKU_GK,
              spotName: data.GENBA_NAME,
            });
          }
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
          // データを取得できなかった場合
          // @TODO データ取得無しの場合の処理を以下に記載。
          // 業務ログ出力
          console.log("getSearchItemList：error 1");
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // @TODO 異常系の処理を以下に記載。
        // 業務ログ出力
        console.log(
          "getSearchItemList：error 2 : " + JSON.stringify(error)
        );
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listV_HACCHU_DATA_WITH_MEISAI", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 他シスへのデータベースの照会
     * ・ リスト内のSQL文をもとにデータベースから照会する
     */
    async executeHanbaikanriSql(sqlList){
      // メソッド名を定義
      const method_name="executeHanbaikanriSql"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let condition = { SQLs: sqlList };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        console.info({ condition });
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            console.log(responseBody.data);
            this.sqlSearchListResult = null;
            this.sqlSearchListResult = responseBody.data;
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return true;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.error(error);
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
  },
};
</script>